body,
#root {
  min-height: 100vh !important;
  display: flex !important;
  flex-direction: column !important;
  margin: 0 !important;
  padding: 0 !important;
}
@supports (-webkit-touch-callout: none) {
  body,
  #root {
    min-height: -webkit-fill-available !important;
  }
}

.intl-tel-input .country-list {
  max-width: 277px !important;
}

.animated-gradient {
  background: linear-gradient(
    -45deg,
    #3366ff,
    #fb5864,
    #ff4b40,
    #8bf1ff,
    #ffc670
  );
  background-size: 400% 400%;
  animation: gradientAnimation 20s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.unselectable-text {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  cursor: "pointer" !important;
  pointer-events: none !important;
}
